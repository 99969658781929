<template>
  <div>
    <Form :options="formOptions"></Form>
  </div>
</template>
<script>
import Form from '../../../../src/components/form/Form.vue'
import axios from '@/components/axios'
import moment from 'moment'
const baseApi = process.env.VUE_APP_APIENDPOINT
export default {
  data () {
    return {
      formOptions: {
        formTitle: `${
          this.$route.query.id ? 'Edit MaterialInward' : 'Add MaterialInward'
        }`,
        submitRouterPath: '/crm/materialinward',
        usersDropdown: true,
        method: 'post',
        action: 'add',
        url: `${baseApi}/materialinward`,
        inputFields: [
          {
            name: 'branch',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'company',
            value: 1,
            type: 'hidden'
          },
          {
            name: 'active',
            value: 1,
            type: 'hidden'
          },
          {
            label: 'Material inward No',
            name: 'materialinwardno',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            // url: "modules",
            required: true,
            options: []
          },
          {
            label: 'Supplier',
            name: 'supplier',
            value: '',
            type: 'text',
            class: 'col-md-6',
            placeholder: 'Enter Supplier',
            required: true,
            options: []
          },
          {
            label: 'Bill Date',
            name: 'billdate',
            value: moment(new Date()).format('yyyy-MM-DD'),
            type: 'date',
            placeholder: 'Enter Bill Date',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Supplier Address',
            name: 'supplieraddress',
            value: '',
            type: 'text',
            class: 'col-md-6',
            placeholder: 'Enter Supplier Address',
            required: true
          },
          {
            label: 'Supplier Mobile',
            name: 'suppliermobile',
            value: '',
            type: 'number',
            class: 'col-md-6',
            placeholder: 'Enter Supplier Mobile',
            required: true
          },
          {
            label: 'Supplier Email',
            name: 'supplieremail',
            value: '',
            type: 'text',
            class: 'col-md-6',
            placeholder: 'Enter Supplier Email',
            required: true
          },
          {
            label: 'Supplier Email',
            name: 'supplieremail',
            value: '',
            type: 'email',
            class: 'col-md-6',
            placeholder: 'Enter Supplier Email',
            required: true,
            options: []
          },
          {
            label: 'Company Name',
            name: 'companyname',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            // url: "modules",
            required: true,
            options: []
          },
          {
            label: 'Project Name',
            name: 'projectname',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            // url: "modules",
            required: true,
            options: []
          },
          {
            label: 'Due Days',
            name: 'duedays',
            value: '',
            type: 'number',
            class: 'col-md-6',
            placeholder: 'Enter Due Days',
            required: true
          },
          {
            label: 'Due Date',
            name: 'duedate',
            value: moment(new Date()).format('yyyy-MM-DD'),
            type: 'date',
            placeholder: 'Enter Due Date',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Remarks',
            name: 'remarks',
            value: '',
            type: 'text',
            class: 'col-md-6',
            placeholder: 'Enter Remarks',
            required: true
          },
          {
            label: 'Invoice No',
            name: 'invoiceno',
            value: '',
            type: 'number',
            class: 'col-md-6',
            placeholder: 'Enter Invoice No',
            required: true
          },
          {
            label: 'Invoice Date',
            name: 'invoicedate',
            value: moment(new Date()).format('yyyy-MM-DD'),
            type: 'date',
            placeholder: 'Enter Invoice Date',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Auth Person',
            name: 'authperson',
            value: '',
            type: 'text',
            class: 'col-md-6',
            placeholder: 'Enter Auth Person',
            required: true
          },
          {
            label: 'Barter %',
            name: 'barter',
            value: '',
            type: 'number',
            class: 'col-md-6',
            placeholder: 'Enter Auth Person',
            required: true
          },
          {
            label: 'Bank',
            name: 'bank',
            value: '',
            type: 'dropdown',
            class: 'col-md-6',
            // url: "modules",
            required: true,
            options: []
          },
          {
            label: 'Attechment 1',
            name: 'attechment1',
            value: '',
            type: 'fileinput',
            placeholder: 'select Attechment 1',
            class: 'col-md-6',
            required: true
          },
          {
            label: 'Attechment 2',
            name: 'attechment2',
            value: '',
            type: 'fileinput',
            placeholder: 'select Attechment 2',
            class: 'col-md-6',
            required: true
          },
          {
            title: 'Product Info',
            type: 'formrepeater',
            inputFields: [
              {
                label: 'Category',
                name: 'Category',
                value: '',
                type: 'dropdown',
                class: 'col-md-2',
                required: true
                // url: "",
              },
              {
                label: 'Product Name',
                name: 'productname',
                value: '',
                type: 'text',
                placeholder: 'Enter Product Name',
                class: 'col-md-2',
                required: true
              },
              {
                label: 'Qty',
                name: 'qty',
                value: '',
                type: 'number',
                placeholder: 'Enter Qty',
                class: 'col-md-2',
                required: true
              },
              {
                label: 'Unit',
                name: 'unit',
                value: '',
                type: 'dropdown',
                class: 'col-md-2',
                required: true
                // url: "",
              },
              {
                label: 'Rate',
                name: 'rate',
                value: '',
                type: 'number',
                placeholder: 'Enter Rate',
                class: 'col-md-1',
                required: true
              },
              {
                label: 'Amount',
                name: 'amount',
                value: '',
                type: 'number',
                placeholder: 'Enter Amount',
                class: 'col-md-1',
                required: true
              }
            ]
          },
          {
            label: 'Cash Amount',
            name: 'cashamount',
            value: '',
            type: 'number',
            class: 'col-md-6',
            placeholder: 'Enter Cash Amount',
            disable: true
          },
          {
            label: 'Barter Amount',
            name: 'barteramount',
            value: '',
            type: 'number',
            class: 'col-md-6',
            placeholder: 'Enter Barter Amount',
            disable: true
          },
          {
            label: 'Total',
            name: 'total',
            value: '',
            type: 'number',
            class: 'col-md-6',
            placeholder: 'Enter Total',
            disable: true
          }
        ]
      }
    }
  },
  components: {
    Form
  },
  beforeMount () {
    const accessToken = localStorage.getItem('accessToken')
    const baseApi = process.env.VUE_APP_APIENDPOINT
    this.formOptions.inputFields.map(async (item) => {
      if (item.type == 'dropdown' && item.url) {
        const requestOptionsUsers = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
          },
          url: `${baseApi}/${item.url}`
        }

        await axios(requestOptionsUsers)
          .then((response) => {
            response.data.data.data
              ? (item.options = response.data.data.data)
              : (item.options = response.data.data)
          })
          .catch((error) => console.log(error, 'error'))
      }
    })
  },
  mounted () {}
}
</script>
